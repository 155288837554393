.wrapper {
    display: flex;
    justify-content: space-evenly;
    gap: clamp(40px, 4vw, 160px);
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 2rem;
    width: 100%;
    max-width: 1500px;
}

.wrapper > div {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.wrapper span {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.5px;
    margin-bottom: 32px;
    margin-top: 7px;
    color: var(--blue);
    transition: color .25s ease-out;
}

.wrapper .links > a, .wrapper .links > div {
    margin-bottom: 20px;
    font-size: 15px;
    font-weight: 500;
}

.wrapper a {
    color: hsla(var(--color-alpha), .8);
    transition: color .2s ease-in-out;
}

.wrapper .links a, .wrapper .links div {
    border-radius: 99px;
}

.wrapper a:hover {
    color: hsla(var(--color-alpha), 1);
}

.wrapper > .about {
    gap: 30px;
}

.tech {
    filter: var(--brightness);
    transition: filter .3s ease-in-out;
}

.wrapper > .accordion {
    display: none;
}

.accordion > div {
    display: flex;
    flex-direction: column;
}

.accordion > div:first-of-type, .accordion > div:first-of-type span {
    border-radius: 12px 12px 0px 0px;
}

.accordion > div:last-of-type, .accordion > div:last-of-type span {
    border-radius: 0px 0px 12px 12px;
}

.accordion .title > span {
    margin: 0;
    display: flex;
    justify-content: space-between;
    padding: 8px 20px;
    cursor: pointer;
    align-items: center;
}

.accordion > div > div:last-of-type {
    height: 0;
    overflow: hidden;
}

.accordion > div > div > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px 0px;
}

.accordion > div > div a {
    padding-left: clamp(10px, 10%, 50px);
}

.plus {
    position: relative;
    height: 18px;
    width: 3px;
    background: var(--color);
    border-radius: 99px;
    margin-right: 12px;
    transition: transform .2s ease-in-out, background-color .3s ease-in-out;
}

.plus:before {
    content: '';
    position: absolute;
    height: 18px;
    width: 3px;
    background: var(--color);
    border-radius: 99px;
    transform: rotate(90deg);
    transition: transform .2s ease-in-out, background-color .3s ease-in-out;
}

.open {
    transform: rotate(90deg);
}

.open:before {
    transform: scale(.01) rotate(135deg);
}

.accordion {
    border-radius: 12px;
}

.createdby {
    margin-bottom: 2rem;
    border-radius: 12px;
    padding: 8px 16px;
    background: var(--container);
    flex: 1;
    font-size: 16px;
    font-weight: 500;
    transition: background-color .3s ease-in-out;
}

.createdby a {
    text-decoration: underline;
    color: var(--color);
    transition: color .3s ease-in-out;
    border-radius: 99px;
}

.createdby a:hover, .createdby a:focus-within {
    color: hsl(212, 100%, 50%);
}

@media screen and (max-width: 1000px) {
    .wrapper {
        flex-direction: column;
        align-items: center;
        gap: 0;
    }
    .wrapper > .about {
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: clamp(20px, 8%, 100px);
    }
    .about > div {
        order: 2;
    }
    .about > p {
        order: 3;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 36px;
        margin-top: 1rem;
    }
    .about > p > br, .about > p > br:after {
        content: ' ';
    }
    .wrapper .links {
        display: none;
    }
    .wrapper > .accordion {
        display: flex;
        width: clamp(16rem, 100%, 35rem);
    }
    .wrapper > .accordion > div {
        background: var(--container);
        transition: background-color .3s ease-in-out;
    }
}