.connectbtn {
    background: var(--color);
    color: var(--bg-color);
    border-radius: 999px;
    line-height: 1.2;
    height: 34px;
    border: 1px solid hsla(0, 0%, 0%, 0);
    transition: background-color .3s ease-in-out, color .3s ease-out, border .3s ease;
    user-select: none;
}

.connectbtn:hover, .connectbtn:focus-within {
    border: 1px solid hsla(261, 95%, 57%, 1);
}

.connectbtn p {
    line-height: .7rem;
    text-align: left;
}

.connectbtn img {
    border-radius: 50%;
}

.connectbtn p:first-of-type {
    font-size: 13px;
}

.connectbtn p:last-of-type {
    color: hsla(var(--color-alpha), .7);
    margin-top: 1.5px;
    font-size: 12px;
    transition: color .25s ease-out;
}

.connectbtn.active {
    background: var(--container);
    color: var(--color);
}