.panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: text;
  padding: 20px 40px;
  gap: 24px;
  font-size: 16px;
}

.imgwrapper {
  overflow: hidden;
  padding: 4px;
  background: var(--container);
  box-shadow:  3px 3px 7px var(--neum-prim),
              -3px -3px 7px var(--neum-sec),
              6px 6px 12px var(--neum-prim),
              -6px -6px 12px var(--neum-sec);
  border-radius: 50%;
}

.imgwrapper img {
  border-radius: 50%;
}

.address {
  border-radius: 99px;
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  padding: 2px 4px 2px 12px;
  box-shadow:  4px 4px 6px 0 var(--neum-prim),
  -4px -4px 6px 0 var(--neum-sec),
  inset -4px -4px 6px 0 var(--neum-sec),
  inset 4px 4px 6px 0 var(--neum-prim);
  overflow: contain;
  cursor: pointer;
  width: fit-content;
  max-width: 100%;
  font-size: 16px;
}

.address p { 
  text-overflow: ellipsis;
  background-color: transparent;
  overflow: hidden; 
  white-space: nowrap;
}

.balance {
background: linear-gradient(135deg, #6a11cb 0%, #2575fc 80%);
border-radius: 12px;
transition: all .3s;
cursor: pointer;
display: flex;
}

.balance2 {
background: var(--container);
transition: all .2s;
display: flex;
flex-direction: column;
gap: .5rem;
justify-content: center;
align-items: center;
padding: 10px 0px;
border-radius: 10px;
min-width: 200px;
box-shadow:  4px 4px 7px var(--neum-prim),
              -4px -4px 7px var(--neum-sec)
}

.balance2:hover {
transform: scale(0.98);
border-radius: 12px;
box-shadow: none;
}

.balance:hover {
box-shadow:  4px 4px 30px 1px hsla(218, 97%, 57%, .15),
              -4px -4px 30px 1px hsla(269, 85%, 43%, .15);
}

.disconnect {
  position: relative;
  z-index: 1;
  padding: 6px 12px;
  border-radius: 8px;
  background: transparent;
  text-transform: uppercase;
  outline: none;
  color: var(--color);
  transition: .25s ease-in-out;
  user-select: none;
  font-size: 16px;
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;
}
.disconnect::before {
  position: absolute;
  border-radius: 40px;
  left: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -2;
  opacity: 1;
  transition: .25s ease-in-out;
  background-image: linear-gradient(225deg, rgb(255, 94, 0) 0%, rgb(230, 0, 82) 100%);
}

.disconnect::after {
  position: absolute;
  border-radius: 40px;
  left: 2px;
  top: 2px;
  content: "";
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  z-index: -1;
  opacity: 1;
  transition: .25s ease-in-out;
  background: var(--bg-color);
}

.disconnect svg {
  fill: var(--color);
  transition: .25s ease-in-out;
}

.disconnect:hover::after, .disconnect:focus-visible::after {
  opacity: 0;
}

.disconnect:hover, .disconnect:focus-visible {
  outline: none;
  color: hsl(0, 0%, 100%);
}

.disconnect:hover svg, .disconnect:focus-visible svg {
  fill: hsl(0, 0%, 100%);
}

.disconnect:active::before{
  box-shadow: inset 0px 0px 12px hsla(0, 0%, 0%, .5);
}

.connectbtn {
  background: var(--container);
  color: var(--bg-color);
  border-radius: 999px;
  line-height: 1.2;
  height: 36px;
  border: 1px solid hsla(var(--bg-color-alpha), 1);
  transition: box-shadow .3s ease-in-out, border .3s ease-in-out;
  user-select: none;
}

.connectbtn:hover {
  border: 1px solid hsla(261, 95%, 57%, 1);
}

.value {
  position: relative;
}

.active {
color: inherit;
box-shadow:  4px 4px 7px var(--neum-prim),
            -4px -4px 7px var(--neum-sec);
}

.balance span {
  font-size: 16px;
  font-weight: 500;
  color: hsla(var(--color-alpha), .7);
}

.balance p {
  font-weight: 500;
}