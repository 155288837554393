.header {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 50;
    background: hsla(var(--bg-color-alpha), .9);
    transition: background-color .3s ease-in-out;
}
.header li {
    text-decoration: none;
    list-style: none;
}
.skip {
    position: absolute;
    top: -100px;
    border-radius: 18px;
    padding: 4px 8px;
    background: var(--color);
    color: var(--bg-color);
    z-index: 90;
    left: 50%;
    transform: translateX(-50%);
}

.skip:focus {
    top: 20px;
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    height: 64px;
    padding: 0px 40px;
    font-size: 14px;
    font-weight: 500;
    transition: box-shadow .3s ease-in-out;
    box-shadow: 0px 2px 14px hsla(0, 0%, 0%, 0);
}

.navbar > ul {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    gap: 1.5rem;
}

.navbar > ul > li {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.navbar > ul > li > p, .navbar > ul > li > a {
    padding: 4px 12px;
    border-radius: 0.5rem;
    transition: background-color .2s ease-out;
    color: var(--blue);
    font-size: 14px;
    font-weight: 600;
}

.navbar > ul > li > a:hover, .navbar > ul > li > a:focus-visible, .navbar > ul > li > .isHovered {
    background: hsla(var(--color-alpha), .07);
}

.dropdownwrapper {
    position: absolute;
    padding-top: 40px;
    top: 14px;
    left: 0;
}

.dropdown {
    background: var(--bg-color);
    box-shadow: 0 0 15px rgba(0,0,0,.15);
    border-radius: 1rem;
    padding: 24px;
    transition: background-color .25s ease-out;
}

.dropdown .split {
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.dropdown .droplinks {
    display: flex;
    flex-direction: column;
    text-wrap: nowrap;
    gap: 0.5rem;
    font-size: 14px;
    font-weight: 600;
    min-width: 200px;
}
.dropdown .droplinks h3 {
    padding-left: 12px;
    margin-bottom: 0.5rem;
}

.dropdown .droplinks a {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: hsla(var(--color-alpha), 0.5);
    padding: 6px 10px;
    padding-left: 14px;
    background: hsla(0, 0%, 0%, 0);
    border-radius: 0.5rem;
    transition: color .25s ease-out, background-color .25s ease-out;
}

.dropdown .droplinks a:hover, .dropdown .droplinks a:focus-visible {
    color: hsla(219, 93%, 59%, 1);
    background: hsla(var(--color-alpha), .07);
}

.spacing {
    display: none;
    margin-right: 40px;
}

.logo {
    color: var(--logo);
    display: flex;
    justify-content: flex-start;
    width: fit-content;
}

.logo > a {
    border-radius: 200px;
    outline-offset: 6px;
}

.user {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
}

.settings {
    position: relative;
    display: flex;
    justify-content: center; 
    align-items: center;
    user-select: none;
}

.stg {
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--container);
    transition: background-color 0.3s ease-in-out;
}

.settings > ul {
    position: absolute;
    padding: 8px 0px;
    top: 52px;
    right: 0px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    width: 300px;
    z-index: 20;
    border-radius: 12px;
    background: var(--container);
    transform-origin: top right;
    transition: background-color .3s ease-in-out;
}

.settings > ul ul {

}

.settings label {
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
}

.settings > ul li {
    padding: 8px 12px;
    margin: 0px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    transition: .2s ease-in-out, color 0s;
    cursor: pointer;
}

.settings > ul li:hover {
    background-color: hsla(var(--color-alpha), 0.08);
}

.settings > ul li > div {
    display: flex;
    align-items: center;
    gap: 8px;
}

.settings > ul svg {
    font-size: 20px;
}

.navm {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    height: 100dvh;
    height: 100svh;
    width: 300px;
    max-width: 100vw;
    z-index: 50;
}
  
.background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    height: 100dvh;
    height: 100svh;
    width: 300px;
    max-width: 100vw;
    border-radius: 0px 20px 20px 0px;
    background: var(--bg-color);
    transition: background-color .3s ease-in-out;
    box-shadow: 4px 0px 12px hsla(0, 0%, 0%, .3);
}
  
.buttonm {
    outline: none;
    border: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 4px;
    top: 12px;
    left: 20px;
    width: 40px;
    height: 40px;
    z-index: 500;
    border-radius: 50%;
    background: transparent;
    color: var(--color);
    transition: color .3s ease-out, background-color .3s ease-out;
}

.buttonm:hover, .buttonm:focus-visible {
    background: hsla(var(--color-alpha), .15);
}

.navm ul, .navm li {
  margin: 0;
  padding: 0;
}

.navm ul {
  padding: 25px;
  position: absolute;
  top: 60px;
  width: 100%;
  max-height: calc(100vh - 160px);
  max-height: calc(100dvh - 160px);
  max-height: calc(100svh - 160px);
  user-select: none;
}

.navm li {
  list-style: none;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  border-radius: 8px;
  user-select: none;
  transition: background-color .25s ease-in-out;
}

.navm li:last-of-type {
    margin-bottom: 0px;
  }

.navm li:hover {
    background: hsla(var(--color-alpha), .12);
}

.navm > ul > li > a {
    display: flex;
    align-items: center;
    padding: 12px 20px;
    width: 100%;
    height: 100%;
    gap: 15px;
}

.navm .active {
    background: hsla(var(--color-alpha), .12);
    font-weight: 700;
}

.navm ul svg {
    color: inherit;
    transition: color .2s ease-in-out;
}

.navm ul .active svg {
    color: hsl(208, 100%, 61%);
}

.iconplaceholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex: 40px 0;
  margin-right: 20px;
}

.textplaceholder {
  border-radius: 5px;
  width: 200px;
  height: 20px;
  flex: 1;
}

.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.navm .menudropdownwrapper:hover {
    background: none;
}

.menudropdown {
    width: 100%;
}

.menuheading {
    display: flex;
    gap: 15px;
    padding: 12px 20px;
    width: 100%;
    border-radius: 8px;
    transition: background-color .25s ease-in-out;
}

.menuheading:hover {
    background: hsla(var(--color-alpha), .12);
}

.menulistwrapper {
    height: 0;
    overflow: hidden;
}

.menulist {
    display: flex;
    flex-direction: column;
    font-size: 16px;
}

.menulist a {
    margin-bottom: 8px;
    border-radius: 8px;
    padding: 12px 26px;
    transition: background-color .25s ease-in-out;
}

.menulist a:first-of-type {
    margin-top: 8px;
}

.menulist a:hover {
    background: hsla(var(--color-alpha), .12);
}

.navdropdown {
    width: 100%;
}

.navheading {
    display: flex;
    gap: 15px;
    color: hsla(var(--color-alpha), 0.5);
    padding: 6px 10px;
    padding-left: 14px;
    width: 100%;
    border-radius: 8px;
    transition: background-color .25s ease-in-out, color .25s ease-out;
}

.navheading:hover {
    color: hsla(219, 93%, 59%, 1);
    background: hsla(var(--color-alpha), .07);
}

.navheading:hover {
    background: hsla(var(--color-alpha), .12);
}

.navlistwrapper {
    height: 0;
    overflow: hidden;
}

.navlist {
    display: flex;
    flex-direction: column;
    font-size: 14px;
}

.navlist a {
    margin-bottom: 8px;
    border-radius: 8px;
    padding: 12px 26px;
    transition: background-color .25s ease-in-out;
}

.navlist a:first-of-type {
    margin-top: 8px;
}

.navlist a:last-of-type {
    margin-bottom: 0px;
}

.navlist a:hover {
    background: hsla(var(--color-alpha), .12);
}

.closed {
    height: 70px;
    width: 70px;
    transition-property: height width;
    transition-delay: .75s;
}

.closed ul {
    pointer-events: none;
    height: 0px;
    width: 0px;
    transition-property: height width;
    transition-delay: .5s;
}

.bsktprice {
    position: absolute;
    bottom: clamp(2rem, 7vw, 10rem);
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
}

.bsktprice > a {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    border-radius: 6px;
    box-shadow: 0 0 20px rgba(0,139,253,0.25);
    padding: 6px 12px;
}

@media screen and (max-width: 950px) {
    .navbar > ul {
        display: none;
    }
    .spacing {
        display: block;
    }
    .navm {
        display: block;
    }
    .logo {
        justify-content: center;
    }
}

@media screen and (max-width: 600px) {
    .navbar {
        padding: 0px 16px;
    }
}

@media screen and (display-mode: standalone) {
    .navbar {
        height: 96px !important;
        padding-top: 32px !important;
    }
}

